import { useRef } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { LoginTypes } from "../../Types/LoginTyps";
import { userLogin } from "../../hooks/userlogin";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/Store";
import { Password } from "primereact/password";





const Home = () => {
  const { register, handleSubmit, formState: { errors }, control } = useForm<LoginTypes>();
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();


  const SubmitLogin: SubmitHandler<LoginTypes> = async (data) => {
    const resData = await userLogin(data);

    if (resData.type === "success" && toast.current) {
      useStore.getState().setUsername(resData.username);
      useStore.getState().setAccessToken(resData.accessToken);
      toast.current.show({ severity: 'success', summary: 'เข้าสู่ระบบสำเร็จ', detail: 'กรูณารอสักครู่ระบบกำลังพาท่านไปหน้าภาพรวม', life: 2000 });
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } else if (resData.error === "Unauthorized" && toast.current) {
      toast.current.show({ severity: 'error', summary: 'เข้าสู่ระบบสำเร็จ', detail: resData.message, life: 2000 });
    } else if (resData.type === "error" && toast.current) {
      toast.current.show({ severity: 'error', summary: 'เกิดข้อผิดพลาด', detail: resData.message, life: 2000 });

    }
  };

  return (
    <>
      <Toast ref={toast} />
      <main className="lg:flex lg:min-h-screen lg:flex-col-2 lg:items-center lg:justify-center lg:p-24 mt-8">
        <div className="mx-8">
          <Image src='/mobilemocup.png' />
        </div>
        <div className="lg:flex lg:flex-col lg:w-64 p-5 flex flex-col">
          <Image src="./Lnw_logo400x100_t_0.png" />
          <h1 className="text-white text-center">ลงชื่อเข้าใช้งาน</h1>
          <form className="flex flex-col gap-2" onSubmit={handleSubmit(SubmitLogin)}>

            <InputText
              {...register('username', { required: true })}
              placeholder="ชื่อผู้ใช้งาน"
              className="px-3 py-2"
              
            />
            {errors.username && <p className="text-red-500">กรุณากรอกรหัสผ่าน</p>}
            <Controller
              control={control}
              name="password"
              rules={{ required: true }}
              render={({ field }) => (
                <Password
                  {...field}
                  toggleMask
                  feedback={false}
                  className={`px-3 py-2 ${errors.password ? 'p-invalid' : ''}`}
                  placeholder="รหัสผ่าน"
                  size={8}
                  pt= {{
                    input: {className: "w-full px-3 py-2.5"}
                  }}
                />
              )}
            />
            {errors.password && <p className="text-red-500">Password is required</p>}
            <Button type="submit" className="bg-red-700 w-full text-white border-yellow-500 hover:bg-yellow-500 hover:border-yellow-500" label="เข้าสู่ระบบ" size="small" />
          </form>
          <div className="flex justify-end">
            <p className="m-2 text-xs hover:underline hover:cursor-pointer" onClick={() => navigate('/register')}>สมัครสมาชิกใหม่</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;