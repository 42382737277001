import React, { useState } from 'react';
import { useStore } from '../hooks/Store';
import ActityLogo from '../../src/Images/LnwPalace_icon.png';
import Swal from 'sweetalert2';

interface proptype {
    creditBalance: number | undefined;
}

const FloatingIcon: React.FC<proptype> = ({ creditBalance }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { username } = useStore();
    const encodedUsername = btoa(username);
    const href = `https://lnwpalace.com/member-login?user=${encodedUsername}`;

    const handleClose = (e: any) => {
        e.preventDefault();
        setIsVisible(false);
    };

    if (!isVisible) return null;


    const handleClick = () => {
        if (creditBalance !== undefined && creditBalance <= 0) {
            Swal.fire("กรุณาเติมเงินขั้นต่ำ 100 บาท เพื่อเข้าร่วมกิจกรรม");
        }
    };

    return (
        <div className="relative inline-block">
            <button
                onClick={handleClose}
                className="absolute top-0 right-3 -mr-2 border text-white rounded-full py-3 px-2 h-6 flex items-center justify-center text-2xl">
                ×
            </button>

            {creditBalance !== undefined && creditBalance > 0 ? (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    <img
                        src={ActityLogo}
                        width={150}
                        alt="Actity Logo"
                    // className="animate-bounce"
                    />
                </a>
            ) : (
                <div onClick={handleClick}>
                    <img
                        src={ActityLogo}
                        width={150}
                        alt="Actity Logo"
                    // className="animate-bounce"
                    />
                </div>
            )}
        </div >
    );
};

export default FloatingIcon;