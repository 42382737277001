import { useState, useEffect, useRef } from 'react'
import { faBook, faCoins, faDollar, faEdit, faGamepad, faGifts, faHouseChimney, faMoneyBillTransfer, faPlayCircle, faPowerOff, faRotate, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'primereact/divider'
import { useStore } from '../../hooks/Store'
import { GetMemberPorfile, GetUserBalance } from '../../hooks/UserDetail'
import { BalanceDetailTypes, MemberProfileDetailsTyps } from '../../Types/UserDatailTypes'
import { useNavigate } from 'react-router-dom';
import { GetgameLink } from '../../hooks/GetGamelink'
import { Toast } from 'primereact/toast'
import { GlobalConfig } from '../../hooks/Config'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button'
import { FreeCreditDetail, GetFreeCredit } from '../../hooks/FreeCredit'
import Swal from 'sweetalert2'
import { CreditDailType } from '../../Types/CreditDetailType'
import ANTIBOT from '../../Images/ANTI-BOT.jpg'
import FloatingIcon from '../../component/FloatingIcon'



const Dashboard = () => {
  const { username } = useStore();
  const { clearSession } = useStore();
  const [balance, Setbalance] = useState<BalanceDetailTypes>();
  console.log(balance);
  const [memberPorfile, setMemberPorfile] = useState<MemberProfileDetailsTyps>();
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [visible, setVisible] = useState(false);
  const [gamelink, Setgamelink] = useState();
  const [freecredit, setFreecredit] = useState<CreditDailType[]>([]);


  const LineSupport = () => {
    window.open(`${GlobalConfig.LINESUPPORT}`, '_blank');
  };

  const userBlance = async () => {
    try {
      const resBalance = await GetUserBalance(username);
      Setbalance(resBalance);

    } catch (error) {
      console.error('Failed to fetch balance:', error);
    }
  };

  const fetchMemberProfile = async () => {
    const resProfile = await GetMemberPorfile(username);
    setMemberPorfile(resProfile);
    if (resProfile.verify === 0) {
      setVisible(true);
    }
  }

  const fetchGameLink = async () => {
    const resLink = await GetgameLink(username);
    Setgamelink(resLink.url)
  };

  const fetchCreditDetail = async () => {
    const resFreeCredit = await FreeCreditDetail(username);
    setFreecredit(resFreeCredit);
  };

  useEffect(() => {
    userBlance();
    fetchMemberProfile();
    fetchGameLink();
    fetchCreditDetail();
  }, []);

  const handleLogout = () => {
    clearSession();
    navigate("/");
  };

  const SubmitFreeCredit = async (username: string) => {
    const resFreeCredit = await GetFreeCredit(username);
    try {
      if (resFreeCredit.type === 'error') {
        Swal.fire({
          icon: 'error',
          title: 'ขออภัย!!',
          text: resFreeCredit.msg,
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'รับเครดิตสำเร็จ',
          text: resFreeCredit.msg,
        });
      }

    } catch (error) {
      console.log("error from getFreeCredit", error);
    }
  }

  const formatDateToThaiDate = (dateString: any) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Asia/Bangkok'
    });
    return formatter.format(date);
  }

  const handleClick = () => {
    if (balance?.totalBetCredit !== undefined && balance.totalBetCredit <= 0) {
      Swal.fire("กรุณาเติมเงินขั้นต่ำ 100 บาท เพื่อเข้าร่วมกิจกรรม");
    } else {
      const encodedUsername = btoa(username);
      window.open(`https://lnwpalace.com/member-login?user=${encodedUsername}`);
    }

  };

  return (
    <>
      <div className=" sticky  top-3/4 w-[157px]">

        <FloatingIcon  creditBalance={balance?.totalBetCredit}/>


      </div>
      <Toast ref={toast} />
      <main className='mx-4 flex flex-col gap-2 lg:items-center'>
        <div className='flex flex-col  border-yellow-500 border-2 rounded-xl p-4 bg-black/80 gap-2 lg:w-96'>
          <h1>ข้อมูลการใช้งาน</h1>
          <Divider />
          <div className='flex flex-col gap-2 '>
            <div className='flex justify-center gap-2'>
              <div className='flex flex-col justify-center border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer basis-1/2'>
                <FontAwesomeIcon icon={faUser} className='text-yellow-500 text-3xl' />
                <h1 className='text-[10px] text-center'>Username</h1>
                <p className='text-center'>{memberPorfile?.username}</p>
              </div>
              <div className='flex flex-col justify-center border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer basis-1/2'>
                <FontAwesomeIcon icon={faDollar} className='text-yellow-500 text-3xl' />
                <h1 className='text-[10px] text-center'>เครดิตคงเหลือ </h1>
                <p className='text-center'>{balance?.message === "Member not found" ? <span className='text-red-500'>กรุณาเติมเงินครั้งแรก</span> : <span>{balance?.totalBetCredit}</span>} </p>
              </div>
            </div>
            <div className='flex flex-col justify-center  border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer basis-1/2 '>
              <h1 className='text-center lg:text-left'><FontAwesomeIcon icon={faBook} className='text-3xl' /></h1>
              <h1 className='text-[10px] text-center lg:text-left'>ข้อมูลบัญชี </h1>
              <div>
                <p>ชื่อ-สกุล : <span>{memberPorfile?.firstname} {memberPorfile?.lastname}</span></p>
                <p>เบอร์ติดต่อ : <span>{memberPorfile?.mobile}</span></p>
                <p>ข้อมูลธนาคาร : <span>{memberPorfile?.bankName} | {memberPorfile?.acctNo}</span></p>
                <p>สถานะ : <span>{memberPorfile?.verify === 1 ? <span className='text-green-500'>ยืนยัน OTP แล้ว</span> : <span className='text-red-500'>ยังไม่ได้ยืนยัน OTP</span>}</span> </p>
              </div>
            </div>
          </div>
        </div>

        {freecredit.filter(credit => credit.status === "0").length > 0 && (
          <div className=' border-yellow-500 border-2 rounded-xl p-4 bg-black/80  lg:w-96'>
            {freecredit.filter(credit => credit.status === "0").map((credit) => (
              <div key={credit.id}> {/* Unique key for each item */}
                <div className='flex flex-row justify-between'>
                  <div>
                    <FontAwesomeIcon icon={faCoins} className='text-yellow-500 text-4xl border-2 p-2 border-yellow-500 rounded-full' />
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <h2 className=''>ยอดฟรีเครดิต</h2>
                    <p className='text-xl'>฿ {credit.total} </p>
                  </div>
                </div>
                <div className='flex flex-row justify-between text-xs'>
                  <th className='text-gray-400 text-left'>
                    <tr>ยอดเทิร์น :</tr>
                    <tr>วันเริ่มต้น : </tr>
                    <tr>วันสิ้นสุด :</tr>
                    <tr>วันที่สร้าง : </tr>
                    <tr>วันที่อัฟเดท :</tr>
                  </th>
                  <td className='text-gray-400 text-right'>
                    <tr>{credit.turnover}</tr>
                    <tr>{formatDateToThaiDate(credit.start)}</tr>
                    <tr>{formatDateToThaiDate(credit.end)}</tr>
                    <tr>{formatDateToThaiDate(credit.createdAt)}</tr>
                    <tr>{formatDateToThaiDate(credit.updatedAt)}</tr>
                  </td>
                </div>
                <Divider />
              </div>
            ))}
            <div className='flex justify-center'>
              <Button label='รับฟรีเครดิต' size='small' onClick={() => SubmitFreeCredit(username)} severity="danger" />
            </div>
          </div>
        )}

        <div className='grid grid-cols-2 border-yellow-500 border-2 rounded-xl p-4 bg-black/80  gap-1 lg:grid lg:grid-cols-3 lg:w-96'>
          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={() => navigate('deposit')}>
            <FontAwesomeIcon icon={faMoneyBillTransfer} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>ฝากเงิน</h1>
          </div>
          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={() => navigate('withdraw')}>
            <FontAwesomeIcon icon={faRotate} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>ถอนเงิน</h1>
          </div>


          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={() => navigate('promotion')}>
            <FontAwesomeIcon icon={faGifts} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>โบนัส</h1>
          </div>
          <a href={balance?.message === "Member not found" ? undefined : gamelink} target='_blank'>
            <div className={`flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 ${balance?.message === "Member not found" ? 'opacity-50 pointer-events-none' : 'hover:cursor-pointer'}`}>
              <FontAwesomeIcon icon={faPlayCircle} className='text-yellow-500 text-3xl' />
              <h1 className='text-center text-sm'>เข้าเล่น</h1>
            </div>
          </a>

          <a href={`${GlobalConfig.LINESUPPORT}`} target="_blank" style={{ textDecoration: 'none' }}>

            <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer'>
              <FontAwesomeIcon icon={faHouseChimney} className='text-yellow-500 text-3xl' />
              <h1 className='text-center text-sm'>ติดต่อเรา</h1>
            </div>
          </a>
          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer'>
            <FontAwesomeIcon icon={faEdit} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>แก้ไขข้อมูล</h1>
          </div>
          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={() => navigate('freecreditreport')}>
            <FontAwesomeIcon icon={faCoins} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>ประวัติเครดิต</h1>
          </div>

          <div className=' flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={handleClick}>
            <FontAwesomeIcon icon={faGamepad} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>กิจกรรมตำหนักเทพ</h1>
          </div>




          <div className='flex flex-col justify-center basis-1/2 border-2 border-yellow-500 rounded-xl p-4 hover:cursor-pointer' onClick={handleLogout}>
            <FontAwesomeIcon icon={faPowerOff} className='text-yellow-500 text-3xl' />
            <h1 className='text-center text-sm'>ออกจากระบบ</h1>
          </div>
        </div>
        <div className='lg:w-96'>
          <img src={ANTIBOT} alt="" />
        </div>
        <Dialog header="! กรุณาติดต่อ Support" visible={visible} style={{ width: '80vw' }} onHide={() => setVisible(false)}>
          <div className='flex flex-col gap-3 justify-center'>
            <p className='text-gray-500'>บัญชีของท่านยังไม่ได้ทำการยืนยัน OTP กรุณาติดต่อผู้ดูแลเพื่อทำการขอ Active บัญชีของท่าน หากท่านไม่ทำการ Active ท่านจะไม่สามารถเข้าเล่นหรือทำธุรกรรมใดๆได้</p>
            <Button label='ติดต่อเรา' size='small' severity='success' onClick={LineSupport} />
          </div>
        </Dialog>
      </main>
    </>

  )
}

export default Dashboard